<template>
  <div class="all">
    <div class="headerImg">
      <img mode="widthFix" src="@/assets/images/home/Head image.png" alt="" />
      <!-- <div class="word">趣恋--让恋爱更加有趣~</div> -->
    </div>
    <div class="title">
      <!-- 左侧情书主题模块 -->
      <div class="theme">
        <!-- 图标加文字 -->
        <div class="up">
          <!-- 左侧f图标 -->
          <div class="icon">
            <img mode="widthFix" src="@/assets/images/home/f.png" alt="" />
          </div>
          <!-- 文字部分 -->
          <div class="word">情书主题</div>
        </div>
        <!-- 下边框 -->
        <!-- <div class="border">
          <div class="top"></div>
          <div class="bottom"></div>
        </div> -->
      </div>
      <!-- 右侧选择模板开始制作 -->
      <div class="start">选择模板 开始制作</div>
    </div>
    <div class="main">
      <div class="newYear" @click="toDisplay('newYear')">
        <img src="@/assets/images/home/newYear.png" alt="" />
        <div class="word">新年祝福</div>
      </div>

      <div class="birthday" @click="toDisplay('birthday')">
        <img src="@/assets/images/home/birthday.png" alt="" />
        <div class="word">生日祝福</div>
      </div>

      <div class="anniversary" @click="toDisplay('commemoration')">
        <img src="@/assets/images/home/commemoration.png" alt="" />
        <div class="word">恋爱纪念日</div>
      </div>

      <div class="Letter" @click="toDisplay('loveLetter')">
        <img src="@/assets/images/home/loveLetter.png" alt="" />
        <div class="word">浪漫情书</div>
      </div>

      <div class="Valentine" @click="toDisplay('valentine')">
        <img src="@/assets/images/home/valentine.png" alt="" />
        <div class="word">情人节祝福</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  methods: {
    toDisplay(param) {
      this.$router.push(`/display/${param}/${this.$route.params.UUID}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.all {
  padding-top: 4vw;
  line-height: 20px;
  font-family: "PingFangSC-regular";
  overflow-y: auto;
  width: 92%;
  margin: 0 auto;
  height: 100vh;
}
.clearfix:after {
  visibility: hidden;
  clear: both;
  display: block;
  content: ".";
  height: 0;
}

.clearfix {
  *zoom: 1;
}
header {
  margin-top: 44px;
  width: 100%;
  height: 44px;
  font-size: 18px;
  color: #101010;
  text-align: center;
  line-height: 26px;
}
.headerImg {
  position: relative;
  width: 100%;
  img {
    margin: 0;
    width: 100%;
    border-radius: 6px;
  }
  .word {
    position: absolute;
    bottom: 2vw;
    width: 100%;
    text-align: center;
    font-size: 3vw;
    color: #754448;
    white-space: nowrap;
  }
}
.title {
  height: 32px;
  margin: 20px auto;
}
.theme {
  float: left;
}
.theme .up {
  height: 24px;
  margin-bottom: 1vw;
}
.theme .icon {
  width: 5.8vw;
  float: left;

  img {
    width: 100%;
  }
}
.theme .word {
  float: left;
  margin-left: 9px;
  font-size: 4vw;
}
.theme .border .top {
  width: 10vw;
  height: 1vw;
  background-color: #ffc2c2;
}
.theme .border .bottom {
  width: 15vw;
  height: 1vw;
  background-color: #d9d5ab;
}
.start {
  float: right;
  color: #ababab;
  font-family: "AlibabaPuHui-regular";
  font-size: 2.8vw;
  margin-top: 8px;
}
.main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;

  & > div {
    width: 45%;
    margin-bottom: 15px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba($color: #000000, $alpha: .2);

    img {
      width: 100%;
    }

    .word {
      position: absolute;
      width: 100%;
      line-height: 3vw;
      padding-left: 7px;
      padding-bottom: 7px;
      bottom: 0;
      color: #000;
      font-size: 3vw;
      font-weight: bold;
    }
  }
}
</style>